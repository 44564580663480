<template>
    <div id="app">
        <a-modal v-model="show" title="单位信息" :width="600" @ok="doSubmit" @cancel="_close" centered>
            <a-form-model ref="form" :model="formData" :rules="formRule">
                <a-row :gutter="20">
                    <a-col span="16">
                        <a-form-model-item label="单位名称" label-position="top" prop="name">
                            <a-input v-model="formData.name" placeholder="请输入单位名称"></a-input>
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row :gutter="20">
                    <a-col span="12">
                        <a-form-model-item label="电话" label-position="top" prop="tel">
                            <a-input v-model="formData.tel" placeholder="请输入单位电话"></a-input>
                        </a-form-model-item>
                    </a-col>
                    <a-col span="12">
                        <a-form-model-item label="状态" label-position="top" prop="status" required>
                            <a-radio-group button-style="solid" v-model="formData.status" :false-value="0" :true-value="1">
                                <a-radio-button value="启用">启用</a-radio-button>
                                <a-radio-button value="禁用">禁用</a-radio-button>
                            </a-radio-group>
                        </a-form-model-item>
                    </a-col>
                </a-row>

            </a-form-model>
            <a-spin size="large" fix v-if="loading" />
        </a-modal>
    </div>
</template>

<script>
import request, { apiUrl, editorUpload } from "@/config/request";
import utils from "@/common/utils";
import apiUtil from "@/common/apiUtil";
export default {
    data() {
        return {
            show: false,
            loading: false,
            styles: {
                height: 'calc(100% - 55px)',
                overflow: 'auto',
                paddingBottom: '53px',
                position: 'static'
            },
            formData: {
                name: '',
                tel:'',
                status: '启用'
            },
            formRule: {
                name: [{ required: true, message: '请输入单位名称' }],
            }
        }
    },
    mounted() {

    },
    methods: {
        editorUpload,
        _show(form) {
            this.show = true;
            this.$nextTick(() => {
                this.$refs.form.resetFields();
                if (!form) {
                    this.formData.organId = '';
                } else {
                    this.formData = JSON.parse(JSON.stringify(form));
                }
            })

        },
        _close() {
            this.show = false;
            this.$emit('close')
        },
        doSubmit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    utils.showSpin();
                    request.post('/platform/organ/save', this.formData).then(ret => {
                        // console.log(ret)
                        this.show = false;
                        if (ret.code == 200) {
                            utils.success('操作成功！').then(() => {
                                this.$refs.form.resetFields();
                                this.show = false;
                                this.$emit('success');
                            })
                        }
                    })
                }
            })
        }
    }
}
</script>

<style scoped>
.demo-drawer-footer {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: 1px solid #e8e8e8;
    padding: 10px 16px;
    text-align: right;
    background: #fff;
}
</style>
