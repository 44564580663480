<template>
	<div id="ap">
		<a-layout>
			<div class="content-header">
				<a-affix :offset-top="0" style="width: 100%;">
					<a-row type="flex" justify="space-between">
						<a-col span="12">
							<common-page-btn @save="$refs.organForm._show()"></common-page-btn>
						</a-col>
						<a-col span="12">
							<a-row type="flex" align="middle" justify="end" :gutter="3">
								<a-col span="12">
									<a-input v-model="param.keyword" allowClear search @pressEnter="getList"
										@on-clear="getList" placeholder="关键词查询"></a-input>
								</a-col>
							</a-row>
						</a-col>
					</a-row>
				</a-affix>
			</div>
		</a-layout>

		<a-layout>
			<a-layout-content>
				<a-table :scroll="{ x: '100%' }" :pagination="false" rowKey="organId" :style="{ background: '#FFF' }"
					size="small" border :columns="keys" ref="list" :loading="loading" :data-source="data.records">
					<!--状态-->
					<template slot-scope="row" slot="status">
						<template v-if="row == '启用'">
							<a-tooltip :title="row"><a-icon type="check-circle" style="color:#090" /></a-tooltip>
						</template>
						<template v-else>
							<a-tooltip :title="row"><a-icon type="stop" /></a-tooltip>
						</template>
					</template>
					<!--操作-->
					<template slot-scope="row" slot="action">
						<a-button-group shape="circle" size="small">
							<a-tooltip title="编辑"><a-button type="link" v-has="'role:save'" icon="edit"
									@click="$refs.organForm._show(row)"></a-button></a-tooltip>
							<a-tooltip title="删除" v-if="row.organId > 0"><a-button type="link" v-has="'role:del'"
									icon="delete" @click="del(row.organId)"></a-button></a-tooltip>
						</a-button-group>
					</template>
				</a-table>
			</a-layout-content>
		</a-layout>
		<a-row>
			<a-col span="12" offset="6" style="text-align: center; padding: 20px 0;">
				<a-pagination :defaultPageSize="param.limit" :total="data.total" @change="getNext"
					@showSizeChange="setLimit" :current="data.current" />
			</a-col>
		</a-row>
		<a-back-top />
		<organ-form ref="organForm" @success="getList()"></organ-form>
	</div>
</template>

<script>
import apiUtil from '@/common/apiUtil'
import utils from '@/common/utils';
import organForm from './components/organFrom.vue'
export default {
	components: { organForm },
	data() {
		return {
			loading: false,
			data: [],
			organList: apiUtil.getOrganList(),
			param: {
				page: 1,
				limit: 20,
				organId: apiUtil.getOrganId(),
				keyword: ''
			},
			keys: [
				{ title: 'ID', dataIndex: 'organId', width: 100, align: 'center', fixed: 'left' },
				{ title: '单位名称', dataIndex: 'name', align: 'left', ellipsis: true },
				{ title: '电话', dataIndex: 'tel', width: 160, ellipsis: true },
				{ title: '状态', dataIndex: 'status', width: 160, scopedSlots: { customRender: 'status' }, align: 'center', ellipsis: true },
				{ title: '操作', scopedSlots: { customRender: 'action' }, align: 'center', width: 200 }
			]
		};
	},
	created() {
		this.getList();
	},
	mounted() {
	},
	methods: {
		//删除
		del(id) {
			this.utils.confirm('删除后无法恢复，是否确认?').then(() => {
				utils.showSpin();
				this.http.delete('/platform/organ/del/' + id).then(ret => {
					if (ret.code == 200) {
						this.utils.success('操作成功！').then(() => {
							this.getList();
						});
					}
				});
			});
		},
		doRefresh() {
			this.param.page = 1;
			this.getList();
		},
		// 获取数据
		getList() {
			this.utils.loading();
			this.loading = true;
			this.http
				.get('/platform/organ/list', this.param)
				.then(ret => {
					this.data = ret.data;
					this.loading = false;
				})
				.catch(err => {
					this.loading = false;
				});
		},
		//设置每页的显示条数
		setLimit(current, limit) {
			this.param.page = 1;
			this.param.limit = limit;
			this.getList();
		},
		//页码切换
		getNext(page) {
			this.param.page = page;
			this.getList();
		}
	}
};
</script>

<style scoped></style>
